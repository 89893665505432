import React from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useStyles } from './style'
import { useSelector, useDispatch } from 'react-redux'
import { asyncExampleSlice } from '../../reducers'

const SimpleReduxExample = () => {
  const classes = useStyles()
  const dispatcher = useDispatch()
  const query = useSelector(asyncExampleSlice.selectors.selectSearchQuery)
  const repos = useSelector(asyncExampleSlice.selectors.selectRepos)
  const isFetchingRepos = useSelector(asyncExampleSlice.selectors.selectIsFetchingRepos)
  const totalRepos = useSelector(asyncExampleSlice.selectors.selectTotalRepos)

  const { register, handleSubmit } = useForm({
    defaultValues: {
      query
    }
  })

  const onSubmit = data => {
    dispatcher(asyncExampleSlice.actions.setSearchQuery(data.query))
    dispatcher(asyncExampleSlice.actions.fetchRepos())
  }

  const renderRepos = () => {
    return repos.map((repo, index) => (
      <div key={index}>
        <p> Name: {repo.full_name}: <a href={repo.url} target='__blank'>visit</a> </p>
      </div>))
  }

  return (
    <div className={classes.root}>
      <Typography variant='h3'>Simple Async Redux Example</Typography>
      <Typography>
        Search github repos with search keyword: <b>{query}</b> - Total: {totalRepos}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField inputRef={register({ required: true })} name='query' />
        <Button type='submit'> Search </Button>
      </form>

      <div>
        <Typography> Results: </Typography>

        {isFetchingRepos
          ? (
            <>
              <p>Searching...</p>
              <CircularProgress />
            </>
            )
          : renderRepos()}
      </div>
    </div>
  )
}

export default SimpleReduxExample
