import React from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { useStyles } from './style'
import { useSelector, useDispatch } from 'react-redux'
import { simpleExampleSlice } from '../../reducers'

const SimpleReduxExample = () => {
  const classes = useStyles()
  const dispatcher = useDispatch()
  const title = useSelector(simpleExampleSlice.selectors.selectTitle)

  return (
    <div className={classes.root}>
      <Typography variant='h3'>Simple Redux Example</Typography>
      <Typography>
        Redux + Redux Toolkit is how we manage frontend state.
      </Typography>
      <Typography>
        Redux is a well known state manage libary in the frontend world and redux toolkit help us to get up and running a redux ecosystem easily.
        Here is the documentation for redux toolkit
        <a href='https://redux-toolkit.js.org/introduction/quick-start' target='__blank'> Redux Toolkit Docs </a>
      </Typography>

      <Typography> Here is a state comming from redux: <b>{title}</b></Typography>
      <Typography> Try to type something in the box: </Typography>
      <TextField value={title} onChange={(event) => dispatcher(simpleExampleSlice.actions.setTitle(event.target.value))} />
    </div>
  )
}

export default SimpleReduxExample
