import React, { useCallback, useState } from 'react'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Box, Button, Checkbox, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@material-ui/core'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab'
import { DeleteForever } from '@material-ui/icons'
import { useStyles } from './style'
import { todoExampleSlice } from '../../reducers'
import { useDispatch, useSelector } from 'react-redux'

const Todo = () => {
  const [text, setText] = useState('')
  const dispatch = useDispatch()

  const todos = useSelector(todoExampleSlice.selectors.selectTodos)

  const classes = useStyles()
  const [listTodos, addTodo] = useEvent([
    {
      eventName: 'getTodos',
      onSuccess: (payload) => {
        dispatch(todoExampleSlice.actions.setTodos(payload))
      }
    },
    {
      eventName: 'addTodo',
      onSuccess: (payload) => {
        dispatch(todoExampleSlice.actions.addTodo(payload))
        setText('')
      },
      onError: (err) => console.log('Error adding todo', err)
    },
    {
      eventName: 'todoWasAdded',
      subscribeOnInit: true,
      onSuccess: (payload) => {
        dispatch(todoExampleSlice.actions.addTodo(payload))
        dispatch(todoExampleSlice.actions.addLog({
          action: 'Todo was added',
          payload: payload.todo
        }))
      }
    }
  ])

  useEventsOnViewLoad(() => {
    listTodos.trigger()
  }, [listTodos])

  const addTodoHandler = useCallback(() => {
    addTodo.trigger({ text })
  }, [addTodo, text])

  return (
    <>
      <h1>Todo Real Time Example</h1>
      <Box display='flex' flexDirection='row'>
        <TextField
          label='What needs to be done?'
          variant='outlined'
          className={classes.todoInput}
          value={text}
          onChange={(ev) => setText(ev.target.value)}
        />
        <Button variant='contained' onClick={addTodoHandler} disabled={addTodo.isWorking}>Add todo</Button>
      </Box>
      <Box display='flex'>
        <List className={classes.todoList}>
          {todos.map(todo => (
            <TodoItem
              key={todo.id}
              todo={todo}
            />
          ))}
        </List>
        <RealTimeLogs />
      </Box>
    </>
  )
}

const TodoItemStates = {
  BASE: 'base',
  TOGGLED: 'toggled',
  REMOVED: 'removed'
}

const CustomTodoStateBg = {
  [TodoItemStates.BASE]: undefined,
  [TodoItemStates.TOGGLED]: 'green',
  [TodoItemStates.REMOVED]: 'red'
}

const TodoItem = ({ todo }) => {
  const { id, text, done } = todo

  const [customState, setCustomState] = useState(TodoItemStates.BASE)

  const dispatch = useDispatch()

  const [toggleTodo, deleteTodo] = useEvent([
    {
      eventName: 'toggleTodo',
      onSuccess: payload => {
        dispatch(todoExampleSlice.actions.toggleTodo(payload))
      },
      onError: (err) => console.log('Error toggling todo', err)
    },
    {
      eventName: 'deleteTodo',
      onSuccess: () => {
        dispatch(todoExampleSlice.actions.deleteTodo({ id }))
      },
      onError: err => console.log('Error deleting todo', err)
    }
  ])

  const [todoWasToggled, todoWasDeleted] = useEvent([
    {
      eventName: 'todoWasToggled',
      subscribeOnTrigger: true,
      onSuccess: (payload) => {
        dispatch(todoExampleSlice.actions.toggleTodo(payload))
        dispatch(todoExampleSlice.actions.addLog({
          action: 'Todo was toggled',
          payload: payload.todo
        }))
        setCustomState(TodoItemStates.TOGGLED)
        setTimeout(() => {
          setCustomState(TodoItemStates.BASE)
        }, 1000)
      }
    },
    {
      eventName: 'todoWasDeleted',
      subscribeOnTrigger: true,
      onSuccess: () => {
        dispatch(todoExampleSlice.actions.addLog({
          action: 'Todo was deleted',
          payload: todo
        }))
        setCustomState(TodoItemStates.REMOVED)
        setTimeout(() => {
          setCustomState(TodoItemStates.BASE)
          dispatch(todoExampleSlice.actions.deleteTodo({ id }))
        }, 1000)
      }
    }
  ])

  useEventsOnViewLoad(() => {
    if (id) {
      // todoWasToggled.trigger({ id })
      todoWasToggled.subscribeToEvent({ id })
      todoWasDeleted.subscribeToEvent({ id })
    }
  }, [id, todoWasToggled, todoWasDeleted])

  const toggleTodoHandler = useCallback(() => {
    toggleTodo.trigger({ id })
  }, [id, toggleTodo])

  const deleteTodoHandler = useCallback(() => {
    deleteTodo.trigger({ id })
  }, [id, deleteTodo])

  const isWorking = toggleTodo.isWorking || deleteTodo.isWorking

  return (
    <ListItem
      key={id}
      role={undefined}
      disabled={isWorking}
      dense
      button
      style={{
        background: CustomTodoStateBg[customState]
      }}
      onClick={toggleTodoHandler}
    >
      <ListItemIcon>
        <Checkbox
          edge='start'
          checked={done}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
      <ListItemText primary={text} />
      <ListItemSecondaryAction>
        <IconButton edge='end' onClick={deleteTodoHandler} disabled={isWorking}>
          <DeleteForever />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const RealTimeLogs = () => {
  const logs = useSelector(todoExampleSlice.selectors.selectLogs)
  const classes = useStyles()
  if (!logs || !logs.length) return null

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography>Real time logs</Typography>
      <Timeline align='alternate' className={classes.realTimeLogs}>
        {logs.map((log, idx) => (
          <TimelineItem key={idx}>
            <TimelineOppositeContent>
              <Typography color='textSecondary'>{log.date}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography>
                {log.action}
              </Typography>
              <Typography color='textSecondary' variant='caption'>
                {log.payload}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  )
}

export default Todo
