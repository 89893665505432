import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Typography } from '@material-ui/core'
import CodeSnippet from '../code-snippet'
import Link from '@material-ui/core/Link'
import CodeBlock from '../code-block'

const components = {
  code: ({ node, ...props }) => {
    if (props.inline) {
      return <CodeSnippet>{props.children}</CodeSnippet>
    } else {
      return <CodeBlock>{props.children}</CodeBlock>
    }
  },
  a: Link,
  li: ({ node, ...props }) =>
    <li><Typography component='span' {...props} /></li>

}

export default function MarkdownViewer (props) {
  return <ReactMarkdown components={components} {...props} />
}
