import reducer, * as actions from './slice'
import * as selectors from './selectors'
import name from './name'

export default {
  reducer,
  actions,
  selectors,
  name
}
