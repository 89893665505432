import React, { createContext, useReducer } from 'react'

import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'

export const ChatContext = createContext(null)

const initialState = {
  messages: []
}

function reducer (state, { type, message, messages }) {
  switch (type) {
    case 'setMessages':
      return {
        messages: [...messages]
      }
    case 'addMessage':
      return {
        messages: [...state.messages, { ...message }]
      }
    case 'clearMessages':
      return initialState
    default:
      return state
  }
}

export function ChatProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [getMessages, sendMessage] = useEvent([
    {
      eventName: 'getMessages',
      canSubscribe: false,
      onSuccess: ({ messages }) => dispatch({ type: 'setMessages', messages })
    },
    {
      eventName: 'sendMessage',
      subscribeOnInit: true,
      onSuccess: ({ message }) => dispatch({ type: 'addMessage', message })
    }
  ])

  useEventsOnViewLoad(() => {
    getMessages.trigger()
  }, [])

  const exec = {
    sendMessage: (message) => {
      sendMessage.trigger({ message })
    }
  }

  return <ChatContext.Provider value={{ state, exec }}>{children}</ChatContext.Provider>
}
