import React, { useState } from 'react'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useStyles } from './style'
import { Card, Box, Typography } from '@material-ui/core'

const StockTicker = () => {
  const classes = useStyles()

  const [currentPrice, setCurrentPrice] = useState(0)

  const [getCurrentPrice] = useEvent([{
    eventName: 'getCurrentPrice',
    subscribeOnInit: true,
    onSuccess: (payload) => setCurrentPrice(payload.currentPrice)
  }])

  useEventsOnViewLoad(() => {
    getCurrentPrice.trigger()
  }, [getCurrentPrice])

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box p={1}>
          <h3>Current Price:</h3>
          <Typography variant='h2'>
            <span data-test='current-price' data-value={currentPrice}>$ {currentPrice}</span>
          </Typography>
        </Box>
      </Card>
    </div>
  )
}

export default StockTicker
