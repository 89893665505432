import React, { useState } from 'react'
import {
  useEvent,
  useEventsOnViewLoad
} from '@emerald-works/react-event-bus-client'
import { useStyles } from './style'
import {
  Card,
  Box,
  Typography,
  Input,
  InputLabel,
  InputAdornment,
  Button
} from '@material-ui/core'

const StockTickerAdmin = () => {
  const classes = useStyles()

  const [currentPrice, setCurrentPrice] = useState(0)
  const [input, setInput] = useState('')

  const [getCurrentPrice, updatePrice] = useEvent([
    {
      eventName: 'getCurrentPrice',
      subscribeOnInit: true,
      onSuccess: payload => setCurrentPrice(payload.currentPrice)
    },
    {
      eventName: 'updatePrice'
    }
  ])

  const handleClickUpdatePrice = () => {
    updatePrice.trigger({ updatedPrice: input })
    setInput('')
  }

  useEventsOnViewLoad(() => {
    getCurrentPrice.trigger()
  }, [getCurrentPrice])

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box p={1}>
          <h3>Current Price:</h3>
          <Typography variant='h2'>
            <span data-test='price-result' data-value={currentPrice}>
              $ {currentPrice}
            </span>
          </Typography>
        </Box>
      </Card>
      <Card className={classes.card}>
        <Box p={1}>
          <InputLabel htmlFor='updated-price'>New Price</InputLabel>
          <Input
            id='updated-price'
            value={input}
            onChange={event => setInput(event.target.value)}
            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
          />
          <span> </span>
          <Button
            variant='contained'
            color='primary'
            data-test='update-price'
            onClick={handleClickUpdatePrice}
          >
            Update Price
          </Button>
        </Box>
      </Card>
    </div>
  )
}

export default StockTickerAdmin
