import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { useStyles } from './style'

export default function CodeBlock ({ node, children, className = '' }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SyntaxHighlighter language={className.replace('language-', '')}>
        {children}
      </SyntaxHighlighter>
    </div>
  )
}
