import React, { useContext, useState } from 'react'
import { useStyles } from './style'
import { ChatContext } from '../../contexts/chat'

function Chat () {
  const classes = useStyles()
  const [input, setInput] = useState('')
  const { state, exec } = useContext(ChatContext)

  return (
    <div className={classes.root}>
      {state.messages.map((message, index) =>
        <p key={index}>
          {message.text}
        </p>
      )}
      <input onChange={event => setInput(event.target.value)} value={input} />
      <button onClick={() => exec.sendMessage(input)}>Send Message</button>
    </div>
  )
}

export default Chat
