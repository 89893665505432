import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  card: {
    maxWidth: 500,
    margin: '10px auto',
    padding: 20
  }
}))
