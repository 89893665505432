import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary,
    padding: 4,
    borderRadius: 2,
    fontWeight: 'bold'
  }
}))
