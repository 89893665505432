import React from 'react'
import { useForm } from 'react-hook-form'
import { TextField, Link, Grid, Button, Paper, Box } from '@material-ui/core'
import CodeSnippet from '../../components/code-snippet'

function FormExample () {
  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      exampleRequired: 'This is a initial Value.'
    }
  })

  watch('exampleRequired')

  function onSubmit (data) {
    console.log(data)
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <h1>Form example </h1>
        <p>This is a form example. We intent to use the hook interface as much as possible.
          For form handling and validation we use <CodeSnippet>react-hook-form</CodeSnippet> where you can find the
          documentation <Link href='https://react-hook-form.com/get-started#Quickstart' target='__blank'> here </Link>.
        </p>

        <Paper>
          <Box p={3}>
            <form onSubmit={onSubmit}>
              <TextField
                inputRef={register}
                error={errors.exampleRequired}
                helperText={errors.exampleRequired ? 'This field is required' : false}
                name='exampleRequired'
                id='outlined-basic'
                label='Your Input'
                variant='outlined'
              />

              <Box mt={1}>
                <Button variant='contained' color='primary' onClick={handleSubmit(onSubmit)}>Submit</Button>
              </Box>
            </form>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} />
    </Grid>

  )
}

export default FormExample
