import React from 'react'
import { Drawer, Toolbar, List, ListItem, ListItemText } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import { useStyles } from './style'

export default function AppDrawer () {
  const classes = useStyles()

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
      anchor='left'
      variant='permanent'
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          <ListItemLink to='/' primary='Home' />
          <ListItemLink to='/form-example' primary='Form' />
          <ListItemLink to='/i18n-example' primary='i18n' />
          <ListItemLink to='/simple-redux-example' primary='Redux' />
          <ListItemLink to='/async-redux-example' primary='Async Redux' />
          <ListItemLink to='/todo-realtime' primary='Todo (Realtime)' />
          <ListItemLink to='/counter-request-response' primary='Counter (Req & Res)' />
          <ListItemLink to='/counter-request-response-redux' primary='Counter (Redux)' />
          <ListItemLink to='/counter-realtime' primary='Counter (Realtime)' />
          <ListItemLink to='/counter-realtime-side-effects' primary='Counter (Realtime & Side Effects)' />
          <ListItemLink to='/chat' primary='Chat' />
          <ListItemLink to='/chat-context-reducer' primary='Chat (Context + Reducer)' />
          <ListItemLink to='/disconnect-handling' primary='Disconnect Handling' />
          <ListItemLink to='/private-route' primary='Private Route' />
        </List>
      </div>
    </Drawer>
  )
}

function ListItemLink (props) {
  const classes = useStyles()
  const { primary, to } = props
  const location = useLocation()

  const isCurrentRoute = location.pathname === to

  const CustomLink = React.useMemo(() => {
    return React.forwardRef((linkProps, ref) => (
      <Link ref={ref} to={to} {...linkProps} />
    )
    )
  },
  [to]
  )

  return (

    <ListItem button component={CustomLink}>
      {/* <ListItemIcon>{icon}</ListItemIcon> */}
      <ListItemText className={isCurrentRoute ? classes.activeLink : classes.inactiveLink} primary={primary} />
    </ListItem>
  )
}
